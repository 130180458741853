<script>
import appConfig from '@/app.config'
import store from '@/state/store'
export default {
	page: {
		title: 'email and notification settings',
		meta: [{ name: 'description', content: appConfig.description }],
	},
	data() {
		return {
			errors: '',
			loading: false,
			type: 'alert-danger',
			formData: {
				minimum_balance: '',
				minimum_wallet_balance: ''
			},
			rules: {
				minimum_balance: [
					{ required: true, message: 'Enter minimum sms balance', trigger: 'change' }

				],

				minimum_wallet_balance: [
					{ required: true, message: 'Enter minimum wallet balance', trigger: 'change' },
				]
			}
		}
	},
	mounted() {
		store.dispatch('wallet/accountBalance')
			.then(res => {
				this.formData.minimum_balance = res.data.data.min_balance
				this.formData.minimum_wallet_balance = res.data.data.min_wallet_balance
			})


		// Analytics
		this.$mixpanel.track("Notifications Page")
		if (this.$store.getters["auth/currentUser"].country === "CI") {
			this.$mixpanelFranco.track("Notifications Page")
		}
	},
	methods: {
		submitForm(formName) {

			if (this.formData.minimum_balance < 0) {
				this.errors = "Minimum balance must be greater than 1"
				return;
			} else {
				this.errors = '';
			}
			this.loading = true
			this.$refs[formName].validate((valid) => {
				if (valid) {
					store.dispatch('reporting/minimumBalanceAlert', this.formData)
						.then(response => {
							this.loading = false
							this.formData.minimum_balance = response.data.min_balance
							this.formData.minimum_wallet_balance = response.data.min_wallet_balance
							this.$notify({
								title: 'Success',
								message: response.message,
								type: 'success'
							});
						})
						.catch(err => {
							this.loading = false
							if (err.response == undefined) {
								this.errors = ['Network error try again']
							}
							if (err.response && err.response.status === 404) {
								this.errors = err.response.data.data.error;
							}
						})
				} else {
					this.loading = false;
					return false;
				}
			});
		},

	}

}
</script>
<style scoped>
#account-settings {
	height: calc(100vh - 120px);
}

.profile_form {
	width: 385px;
	margin-left: 3em;
}

@media screen and (max-width: 500px) {
	.profile_form {
		width: 100%;
		;
		margin-left: 0px;
	}
}
</style>
<template>
	<div class="col-md-12">
		<div id="account-settings" class="mnotify-card">
			<div id="side-indicators-content" class="mnotify-card-header">
				<div class="profile-settings-section-header">
					<profilesettingsBtn />
					<div class="profile-settings-sub-header">
						<h3 class="mnotify-card-header-text pb-2 my-0">
							{{ $t("dashboard.account_settings.sms_wallet_notification.title") }} </h3>
						<p class="profile-description">
							{{ $t("dashboard.account_settings.sms_wallet_notification.sub_header") }}</p>
					</div>
				</div>
			</div>
			<div class="mnotify-card-body">
				<div class="mnotify-card-body">
					<div class="row profile-settings-form" style="margin-top:2em;">
						<div class="profile_form">
							<div class="profile-inner">
								<div class="col-md-12">
									<p>
										{{ $t("dashboard.account_settings.sms_wallet_notification.form.sms_alert.text") }}</p>
								</div>
								<div class="col-md-12">
									<el-form :model="formData" :rules="rules" ref="ruleForm"
										@submit.prevent.native="checkEnter">
										<div class="form_input_container">
											<el-form-item :label="$t('dashboard.account_settings.sms_wallet_notification.form.sms_alert.label')" prop="minimum_balance">
												<el-input style="width:100%" :controls="false" id="__promo_input"
													:placeholder="$t('dashboard.account_settings.sms_wallet_notification.form.sms_alert.placeholder')"
													v-model="formData.minimum_balance"></el-input>
											</el-form-item>
										</div> <br>
										<div>

											<P>
												{{$t("dashboard.account_settings.sms_wallet_notification.form.wallet_alert.text")}}</P>
										</div>
										<div>
											<div class="form_input_container">
												<el-form-item :label="$t('dashboard.account_settings.sms_wallet_notification.form.wallet_alert.label')" prop="">
													<el-input style="width:100%" :controls="false" id="__promo_input"
														:placeholder="$t('dashboard.account_settings.sms_wallet_notification.form.wallet_alert.placeholder')"
														v-model="formData.minimum_wallet_balance"></el-input>
												</el-form-item>
											</div>
										</div>

										<Alert v-if="errors" :message="errors" :type="type" />
										<div class="terms_and_conditons_container mt-5">
											<el-form-item>
												<el-button id="mNotify_form_submit_btn" :loading="loading" type="primary"
													@click="submitForm('ruleForm')">
													{{ $t("dashboard.account_settings.sms_wallet_notification.form.submit_button") }}
												</el-button>
											</el-form-item>
										</div>
									</el-form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
